import React from "react";
import CustomModal from "../../presentational/Modal";
import { useStoreActions, useStoreState } from "easy-peasy";
import { TouchableOpacity, View } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { height, width } from "../../../utilities/general";
import List from "../../presentational/List";
import Text from "../../presentational/Text";

const OperationListModal = () => {
	const { operationListModalVisible, operations, operationsLoading, opNbr } =
		useStoreState((state) => state);

	const { setOperationListModalVisibleAction, selectOperation } =
		useStoreActions((actions) => actions);

	const Element = ({ data, index }) => (
		<TouchableOpacity
			onPress={async () => {
				selectOperation(data.slice(5));
				setOperationListModalVisibleAction(false);
			}}
		>
			<View
				style={{
					flexDirection: "row",
					alignContent: "center",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{data.startsWith("true") ? (
					<Ionicons
						name="checkmark-circle"
						size={24}
						color={
							operations[index].Material.some(
								(item) => item.QtyRequired.value > item.QtyActual.value
							)
								? "#b2b200"
								: "green"
						}
						style={{
							left: 15,
							top: 10,
						}}
					/>
				) : (
					<Ionicons
						name="ios-close-circle"
						size={24}
						color="red"
						style={{
							left: -15,
							top: 5,
						}}
					/>
				)}

				<Text
					style={{
						fontSize: 25 - height / 150,
						textAlign: "center",
						alignSelf: "center",
						padding: 10,
						textDecorationLine: "underline",
						color: "blue",
					}}
				>
					{data.slice(5)}
				</Text>
			</View>
		</TouchableOpacity>
	);

	return (
		<CustomModal
			isVisible={operationListModalVisible}
			onClose={() => setOperationListModalVisibleAction(false)}
			title="Operations"
			style={{ width: width * 0.9, height: height * 0.9 }}
			width="50%"
			height="50%"
		>
			<List
				maxHeight={1.5}
				listHeight={1.5}
				selectItem={operations.findIndex(
					(item) => item.OperationNbr.value == opNbr.trim()
				)}
				onPress={async (data) => {
					selectOperation(data.props.data.slice(5));
					setOperationListModalVisibleAction(false);
				}}
				loading={operationsLoading}
				tableHead={[
					"Operation Number",
					"Operation Description",
					"Qty To Produce",
					"Qty Complete",
					"Qty Remaining",
					"Operation Status",
				]}
				tableRows={operations.map((item, index) => {
					return [
						<Element
							data={item.checkmark + " " + item.OperationNbr.value}
							index={index}
						/>,
						item.OperationDescription.value,
						item.QtytoProduce.value,
						item.QtyComplete.value,
						item.QtyRemaining.value,
						item.OperationStatus.value,
					];
				})}
			/>
		</CustomModal>
	);
};

export default OperationListModal;
