import React from 'react';
import { View } from 'react-native';
import Card from '../../presentational/Card';
import MaterialList from '../List/MaterialList';
import StepsList from '../List/StepsList';
import ToolsList from '../List/ToolsList';
import { useStoreState } from 'easy-peasy';

const MaterialCard = () => {
  const { materialActive, toolsActive, stepsActive } = useStoreState(
    (state) => state
  );

  return (
    <View
      style={{
        ...styles.cardView,
        flexDirection: 'column',
      }}
    >
      <Card>
        {toolsActive ? (
          <ToolsList />
        ) : stepsActive ? (
          <StepsList />
        ) : materialActive ? (
          <MaterialList />
        ) : (
          <MaterialList />
        )}
      </Card>
    </View>
  );
};

export default MaterialCard;

const styles = {
  cardView: {
    alignSelf: 'center',
    alignContent: 'flex-start',
    marginTop: '1%',
    width: '92%',
  },
};
