import List from "../../presentational/List";
import { useStoreActions, useStoreState } from "easy-peasy";
import { TouchableOpacity, View, Dimensions } from "react-native";
import Text from "../../presentational/Text";
import { Ionicons } from "@expo/vector-icons";

const { height } = Dimensions.get("window");

function OperationsList() {
	const { operations, operationsLoading, opNbr } = useStoreState(
		(state) => state
	);
	const { selectOperation } = useStoreActions((actions) => actions);

	const Element = ({ data, index }) => (
		<TouchableOpacity
			onPress={async () => {
				selectOperation(data.slice(5));
			}}
		>
			<View
				style={{
					flexDirection: "row",
					alignContent: "center",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{data.startsWith("true") ? (
					<Ionicons
						name="checkmark-circle"
						size={24}
						color={
							operations[index].Material.some(
								(item) => item.QtyRequired.value > item.QtyActual.value
							)
								? "#b2b200"
								: "green"
						}
						style={{
							left: 15,
							top: 10,
						}}
					/>
				) : (
					<Ionicons
						name="ios-close-circle"
						size={24}
						color="red"
						style={{
							left: -15,
							top: 5,
						}}
					/>
				)}

				<Text
					style={{
						fontSize: 25 - height / 150,
						textAlign: "center",
						alignSelf: "center",
						padding: 10,
						textDecorationLine: "underline",
						color: "blue",
					}}
				>
					{data.slice(5)}
				</Text>
			</View>
		</TouchableOpacity>
	);

	return (
		<List
			onPress={(data, index) => {
				selectOperation(data.props.data.slice(5));
			}}
			selectItem={operations.findIndex(
				(item) => item.OperationNbr.value == opNbr.trim()
			)}
			loading={operationsLoading}
			tableHead={[
				"Operation Number",
				"Operation Description",
				"Qty To Produce",
				"Qty Complete",
				"Qty Remaining",
				"Operation Status",
			]}
			tableRows={operations.map((item, index) => {
				return [
					<Element
						data={item.checkmark + " " + item.OperationNbr.value}
						index={index}
					/>,
					item.OperationDescription.value,
					item.QtytoProduce.value,
					item.QtyComplete.value,
					item.QtyRemaining.value,
					item.OperationStatus.value,
				];
			})}
		/>
	);
}

export default OperationsList;
