import { CheckBox } from "react-native-elements";
import List from "../../presentational/List";
import { useStoreState } from "easy-peasy";
import { View } from "react-native";
import Text from "../../presentational/Text";
import { Dimensions } from "react-native";

const { height } = Dimensions.get("window");

function MaterialList() {
	const { selectedOperation } = useStoreState((state) => state);

	const Element = ({ data, index }) => (
		<View
			style={{
				flex: 1,
				height: "100%",
				alignSelf: "center",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<CheckBox
				uncheckedColor="black"
				checkedColor="black"
				containerStyle={{
					alignContent: "center",
					alignItems: "center",
					justifyContent: "center",
					height: "50%",
					width: "50%",
				}}
				checked={data}
			/>
		</View>
	);

	const Element2 = ({ data, color }) => (
		<View
			style={{
				flexDirection: "row",
				alignContent: "center",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Text
				style={{
					fontSize: 22 - height / 150,
					textAlign: "center",
					alignSelf: "center",
					padding: 10,
					color: color,
					fontWeight: "bold",
				}}
			>
				{data}
			</Text>
		</View>
	);

	return (
		<List
			onPress={() => {
				console.log();
			}}
			tableHead={[
				"Inventory ID",
				"Description",
				"Qty Required",
				"Total Required",
				"UOM",
				"Qty Issued",
				"Qty Remaining",
				"Batch Size",
			]}
			tableRows={
				selectedOperation &&
				selectedOperation.Material &&
				selectedOperation.Material.length > 0
					? selectedOperation.Material.map((item) => {
							return [
								<Element2
									data={item.InventoryID.value}
									color={
										item.QtyRequired.value < item.QtyActual.value
											? "green"
											: "red"
									}
								/>,
								item.Description.value,
								item.QtyRequired.value,
								item.TotalRequired.value,
								item.UOM.value,
								item.QtyActual.value,
								item.QtyRemaining.value,
								item.BatchSize.value,
							];
					  })
					: []
			}
		/>
	);
}

export default MaterialList;
