import { thunk } from "easy-peasy";
import {
	GetEmployeesAPICall,
	GetWorkCentersAPICall,
	SelectDispatchAPICall,
	SelectWorkCenterAPICall,
} from "../../services";

const workCenterThunks = {
	getWorkCentersThunk: thunk(async (actions, payload, helpers) => {
		actions.setLoadingAction(true);
		try {
			const response = await GetWorkCentersAPICall();
			await actions.addWorkCenters(response.data.data);
		} catch (error) {
			actions.setAlertThunk({
				type: "error",
				message: "Error getting work centers",
			});
		}
		actions.setLoadingAction(false);
	}),
	getWorkCentersThunkNoLoad: thunk(async (actions, payload, helpers) => {
		try {
			const response = await GetWorkCentersAPICall();
			await actions.addWorkCenters(response.data.data);
		} catch (error) {
			actions.setAlertThunk({
				type: "error",
				message: "Error getting work centers",
			});
		}
	}),

	selectWorkCenterThunk: thunk(async (actions, payload, helpers) => {
		actions.setDispatchLoading(true);
		try {
			const response = await SelectWorkCenterAPICall(payload);
			await actions.setSelectedWorkCenter(response.data.data);
		} catch (error) {
			actions.setAlertThunk({
				type: "error",
				message: "Error getting work centers",
			});
		}
		actions.setDispatchLoading(false);
	}),

	selectDispatchThunk: thunk(async (actions, payload, helpers) => {
		actions.setOperationsLoading(true);
		try {
			actions.setOpNbr("");

			actions.setSelectedDispatch(payload);
			const response = await SelectDispatchAPICall(payload);
			await actions.setOperations(response.data.data[0].Operations);
		} catch (error) {
			actions.setAlertThunk({
				type: "error",
				message: "Error getting work centers",
			});
		}
		actions.setOperationsLoading(false);
	}),

	getEmployeesThunk: thunk(async (actions, payload, helpers) => {
		actions.setLoadingAction(true);

		try {
			const response = await GetEmployeesAPICall();
			actions.setEmployees(response.data.data);
		} catch (error) {
			actions.setAlertThunk({
				type: "error",
				message: "Error getting employees",
			});
		}
		actions.setLoadingAction(false);
	}),
};
export default workCenterThunks;
