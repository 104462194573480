import List from "../../presentational/List";
import { useStoreActions, useStoreState } from "easy-peasy";
import { TouchableOpacity, View } from "react-native";
import { EvilIcons, FontAwesome } from "@expo/vector-icons";
import Text from "../../presentational/Text";
import Input from "../../presentational/Input";
import moment from "moment";
import Button from "../../presentational/Button";
import { useState } from "react";
import Icon from "../../presentational/Icon";
import { Input as RNInput } from "@rneui/themed";

function LaborList({ listHeight = 0.75, maxHeight = 0.75 }) {
	const {
		labor: { laborLineItems, input, laborHours, newLaborTime },
		operationsLoading,
		selectedDispatch,
		currentDispatchObject,
	} = useStoreState((state) => state);
	const {
		setNewLaborTimeAction,
		processTimeThunk,
		setNewStartTimeAction,
		setNewEndTimeAction,
		setAreYouSureModalVisibleAction,
		setSelectedLaborIndexAction,
		setLaborInputAction,
		setLaborNoteModalVisibleAction,
	} = useStoreActions((actions) => actions);

	const Element = ({ data, index }) => (
		<Button
			disabled={
				laborLineItems[index].StartTime == null &&
				laborLineItems[index].EndTime == null &&
				laborLineItems[index].LaborTime == laborLineItems[index].newLaborTime
					? true
					: laborLineItems[index].StartTime == null &&
					  laborLineItems[index].EndTime == null &&
					  laborLineItems[index].LaborTime !==
							laborLineItems[index].newLaborTime
					? false
					: laborLineItems[index].LaborTime == "00:01"
					? false
					: moment(laborLineItems[index].StartTime).format("h:mm A") ==
							laborLineItems[index].newStartTime &&
					  moment(laborLineItems[index].EndTime).format("h:mm A") ==
							laborLineItems[index].newEndTime
					? true
					: moment(laborLineItems[index].StartTime).format("h:mm A") !==
					  laborLineItems[index].newStartTime
					? false
					: moment(laborLineItems[index].EndTime).format("h:mm A") !==
					  laborLineItems[index].newEndTime
					? false
					: true
			}
			color={"#00205B"}
			fullWidth
			onPress={() => {
				setSelectedLaborIndexAction(index);
				setAreYouSureModalVisibleAction(true);
			}}
		>
			{laborLineItems[index].StartTime == null &&
			laborLineItems[index].EndTime == null &&
			laborLineItems[index].LaborTime !== laborLineItems[index].newLaborTime
				? "Enter"
				: moment(laborLineItems[index].StartTime).format("h:mm A") ==
						laborLineItems[index].newStartTime &&
				  moment(laborLineItems[index].EndTime).format("h:mm A") ==
						laborLineItems[index].newEndTime
				? "Clock Out"
				: moment(laborLineItems[index].StartTime).format("h:mm A") !==
				  laborLineItems[index].newStartTime
				? "Enter"
				: moment(laborLineItems[index].EndTime).format("h:mm A") !==
				  laborLineItems[index].newEndTime
				? "Enter"
				: "Clock Out"}
			{/* {laborLineItems[index].LaborTime == "00:01" ? "Clock Out" : "Enter"} */}
		</Button>
	);

	return (
		<List
			fontSize={17}
			onPress={(data, index) => {}}
			pageSize={5}
			width={["3%", "17%", "16%", "16%", "16%", "16%", "16%"]}
			listHeight={listHeight}
			maxHeight={maxHeight}
			pagination={true}
			loading={operationsLoading}
			tableHead={[
				"",
				"Employee ID",
				"Operation Number",
				"Start Time",
				"End Time",
				"Labor Time",
				"Clock Out",
			]}
			tableRows={laborLineItems.map((item, index) => {
				return [
					<TouchableOpacity
						style={{
							width: "100%",
							height: "100%",
						}}
						onPress={() => {
							setSelectedLaborIndexAction(index);

							setLaborNoteModalVisibleAction(true);
						}}
					>
						<FontAwesome name="sticky-note" size={24} color="gray" />
					</TouchableOpacity>,

					item.EmployeeID,
					item.OperationID,

					<Input
						inputContainerStyle={{
							alignSelf: "center",
							alignContent: "center",
							alignItems: "center",
							justifyContent: "center",
							textAlign: "center",
							fontSize: 17,
						}}
						inputStyle={{
							alignSelf: "center",
							alignContent: "center",
							alignItems: "center",
							justifyContent: "center",
							textAlign: "center",
							fontSize: 17,
						}}
						style={{
							alignSelf: "center",
							alignContent: "center",
							alignItems: "center",
							justifyContent: "center",
							fontSize: 17,
							color: "#00205b",
							textAlign: "center",
						}}
						textAlign={"center"}
						value={item.newStartTime == "Invalid date" ? "" : item.newStartTime}
						placeholder="Start Time"
						onChangeText={(val) => {
							setNewStartTimeAction({ index: index, input: val });
						}}
						editable={
							// item.LaborTime == "00:01" ||
							// (item.StartTime == null && item.EndTime == null)
							true
						}
					/>,
					<>
						{item.LaborTime == "00:01" ? (
							<></>
						) : (
							<Input
								inputContainerStyle={{
									alignSelf: "center",
									alignContent: "center",
									alignItems: "center",
									justifyContent: "center",
									textAlign: "center",
									fontSize: 17,
								}}
								inputStyle={{
									alignSelf: "center",
									alignContent: "center",
									alignItems: "center",
									justifyContent: "center",
									textAlign: "center",
									fontSize: 17,
								}}
								style={{
									alignSelf: "center",
									alignContent: "center",
									alignItems: "center",
									justifyContent: "center",
									fontSize: 17,
									color: "#00205b",
									textAlign: "center",
								}}
								value={item.newEndTime == "Invalid date" ? "" : item.newEndTime}
								placeholder="End Time"
								editable={
									// item.LaborTime == "00:01" ||
									// (item.StartTime == null && item.EndTime == null)
									true
								}
								onChangeText={(val) => {
									setNewEndTimeAction({ index: index, input: val });
								}}
								onBlur={() => {
									const difference = moment
										.utc(
											moment(item.newEndTime, "h:mm A").diff(
												moment(item.newStartTime, "h:mm A")
											)
										)
										.format("HH:mm");
									if (difference !== "Invalid date") {
										setNewLaborTimeAction({
											index: index,
											input: difference,
										});
									}
								}}
							/>
						)}
					</>,
					item.StartTime == null && item.EndTime == null ? (
						// &&
						// item.LaborTime == "00:01"
						<Input
							inputContainerStyle={{
								alignSelf: "center",
								alignContent: "center",
								alignItems: "center",
								justifyContent: "center",
								textAlign: "center",
								fontSize: 17,
							}}
							inputStyle={{
								alignSelf: "center",
								alignContent: "center",
								alignItems: "center",
								justifyContent: "center",
								textAlign: "center",
								fontSize: 17,
							}}
							style={{
								alignSelf: "center",
								alignContent: "center",
								alignItems: "center",
								justifyContent: "center",
								fontSize: 17,
								textAlign: "center",
								color: "#00205b",
							}}
							value={
								item.newLaborTime == "" ? item.LaborTime : item.newLaborTime
							}
							editable={true}
							onChangeText={(val) => {
								setNewLaborTimeAction({ index: index, input: val });
							}}
						/>
					) : item.LaborTime == "00:01" ? (
						<></>
					) : (
						item.LaborTime
					),

					<Element
						data={item.checkmark + " " + item.OperationNbr}
						index={index}
					/>,
				];
			})}
		/>
	);
}

export default LaborList;
