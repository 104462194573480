import React from 'react';
import { View } from 'react-native';
import Card from '../../presentational/Card';
import ProductionOrderList from '../List/ProductionOrderList';
import DynamicText from '../../presentational/MyText';

const DispatchCard = () => {
  return (
    <View
      style={{
        ...styles.cardView,
        flexDirection: 'column',
      }}
    >
      <DynamicText text={'Production Orders'} fontSize={12} />
      <View style={{ padding: '5px' }} />
      <Card>
        <ProductionOrderList />
      </Card>
    </View>
  );
};

export default DispatchCard;

const styles = {
  cardView: {
    alignSelf: 'center',
    alignContent: 'flex-start',
    marginTop: '1%',
    width: '92%',
  },
};
