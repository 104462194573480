import origAxios from "axios";
import { ERP_TOKEN_NAME } from "../utilities/constants";

export const axios = origAxios.create({ withCredentials: true });
export const acmAxios = origAxios.create({ withCredentials: true });

let baseURL = process.env.REACT_BASE_URL + "/";

export const getAuthenticatedUserApiCall = () =>
	axios({
		method: "get",
		url: `${process.env.REACT_BASE_URL}/user/authenticated`,
	});

export const Authenticate = async (url, payload) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: "post",
				url: `${url}login`,
				data: payload,
			});
			if (response && response.data) {
				return resolve(response);
			}
			return reject("No data was returned");
		} catch (error) {
			return reject(error);
		}
	});
};

export const logout = () => {
	return axios.post(`${process.env.REACT_BASE_URL}/logout`);
};

export const AuthenticateACM = async (url, payload) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: "post",
				url: `${url}acmLogin`,
				data: {
					name: payload.name,
					password: payload.password,
				},
			});
			if (response && response.data) {
				return resolve(response);
			}
			return reject("No data was returned");
		} catch (error) {
			return reject(error);
		}
	});
};

export const GetWorkCentersAPICall = async () => {
	return acmAxios({
		method: "get",
		url: `${baseURL}WorkCenter/WorkCenter`,
	});
};

export const SelectWorkCenterAPICall = async (workCenter) => {
	return acmAxios({
		method: "post",
		url: `${baseURL}WorkCenter/SelectWorkCenter`,
		data: {
			workCenter: workCenter,
		},
	});
};

export const SelectDispatchAPICall = async (dispatch) => {
	return acmAxios({
		method: "post",
		url: `${baseURL}WorkCenter/SelectDispatch`,
		data: {
			dispatch: dispatch,
		},
	});
};

export const CreateMoveAPICall = async (move) => {
	return acmAxios({
		method: "post",
		url: `${baseURL}WorkCenter/CreateMove`,
		data: move,
	});
};

export const GetEmployeesAPICall = async () => {
	return acmAxios({
		method: "get",
		url: `${baseURL}WorkCenter/GetEmployees`,
	});
};

export const CreateLaborAPICall = async (move) => {
	return acmAxios({
		method: "post",
		url: `${baseURL}WorkCenter/CreateLabor`,
		data: move,
	});
};

export const GetFileDetailAPICall = async (dispatch) => {
	return acmAxios({
		method: "post",
		url: `${baseURL}WorkCenter/GetFileDetail`,
		data: {
			dispatch: dispatch,
		},
	});
};

export const PutTaskAPICall = async (task) => {
	return acmAxios({
		method: "post",
		url: `${baseURL}WorkCenter/PutTask`,
		data: task,
	});
};

export const SelectFileAPICall = async (id) => {
	return acmAxios({
		method: "post",
		url: `${baseURL}WorkCenter/SelectFile`,
		data: {
			id: id,
		},
	});
};

export const GetLaborLineItemsAPICall = async (prodNbr, opID, date) => {
	return acmAxios({
		method: "post",
		url: `${baseURL}WorkCenter/GetLaborLineItems`,
		data: {
			prodNbr: prodNbr,
			opID: opID,
			date: date,
		},
	});
};

export const ReleaseLaborAPICall = async (guid) => {
	return acmAxios({
		method: "post",
		url: `${baseURL}WorkCenter/ReleaseLabor`,
		data: {
			guid: guid,
		},
	});
};

export const refreshTokenApiCall = (key) => {
	let url = `${process.env.REACT_BASE_URL}/token/refresh`;
	if (key === ERP_TOKEN_NAME) url += "/erp";
	return axios({
		method: "post",
		url,
	});
};
