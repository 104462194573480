import React from "react";
import CustomModal from "../../presentational/Modal";
import { useStoreActions, useStoreState } from "easy-peasy";
import { TouchableOpacity, View } from "react-native";
import moment from "moment";
import { height, width } from "../../../utilities/general";
import List from "../../presentational/List";
import Text from "../../presentational/Text";

const DispatchListModal = () => {
	const {
		selectedDispatch,
		dispatchListModalVisible,
		workCenterDispatch,
		dispatchLoading,
	} = useStoreState((state) => state);

	const {
		setDispatchListModalVisibleAction,
		setCurrentDispatchObject,
		selectDispatchThunk,
	} = useStoreActions((actions) => actions);

	const Element = ({ data, index }) => (
		<TouchableOpacity
			onPress={async () => {
				setCurrentDispatchObject(workCenterDispatch[index]);
				selectDispatchThunk(data);
				setDispatchListModalVisibleAction(false);
			}}
		>
			<View>
				<Text
					style={{
						fontSize: 25 - height / 150,
						textAlign: "center",
						alignSelf: "center",
						padding: 10,
						textDecorationLine: "underline",
						color: "blue",
					}}
				>
					{data}
				</Text>
			</View>
		</TouchableOpacity>
	);

	return (
		<CustomModal
			isVisible={dispatchListModalVisible}
			onClose={() => setDispatchListModalVisibleAction(false)}
			title="Production Orders"
			style={{ width: width * 0.9, height: height * 0.9 }}
			width="50%"
			height="50%"
		>
			<List
				doNotScroll={false}
				maxHeight={1.5}
				listHeight={1.5}
				selectItem={
					selectedDispatch == ""
						? -1
						: workCenterDispatch.findIndex(
								(item) => item.ProductionNbr === selectedDispatch
						  )
				}
				onPress={(data, index) => {
					selectDispatchThunk(data.props.data);
					setCurrentDispatchObject(workCenterDispatch[data.props.index]);
					setDispatchListModalVisibleAction(false);
				}}
				loading={dispatchLoading}
				tableHead={[
					"Production Number",
					"Description",
					"Inventory ID",
					"Inventory Description",
					"Status",
					"Qty to Produce",
					"Start Date",
					"End Date",
				]}
				tableRows={workCenterDispatch.map((item, index) => {
					return [
						<Element data={item.ProductionNbr} index={index} />,
						item.OrderDescription,
						item.InventoryID,
						item.Description,
						item.Status,
						parseInt(item.QtytoProduce),
						moment(item.StartDate).format("MM/DD/YYYY"),
						moment(item.EndDate).format("MM/DD/YYYY"),
					];
				})}
			/>
		</CustomModal>
	);
};

export default DispatchListModal;
