import React, { useEffect } from "react";
import CustomModal from "../../presentational/Modal";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Divider } from "react-native-elements";
import Col from "../../presentational/Col";
import Row from "../../presentational/Row";
import Input from "../../presentational/Input";
import Button from "../../presentational/Button";
import LaborList from "../List/LaborList";
import { useIsFocused } from "@react-navigation/native";
import { TouchableOpacity, View } from "react-native";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import Gap from "../../presentational/Gap";
import moment from "moment";
import { height, width } from "../../../utilities/general";
import { vhToDp } from "../../../utilities/responsive";
import LaborDatePicker from "../DatePicker/LaborDatePicker";

const LaborModal = () => {
	const {
		selectedDispatch,
		labor: {
			laborModalVisible,
			input,
			laborLineItems,
			laborHours,
			laborMinutes,
			laborDatePickerVisible,
		},
		employees,
		operations,
		opNbr,
		currentDispatchObject,
	} = useStoreState((state) => state);

	const {
		setLaborModalVisible,
		setLaborInputAction,
		createLaborThunk,
		setLaborMinutesAction,
		setLaborHoursAction,
		addLaborLineItemAction,
		getLaborLineItemsThunk,
		releaseLaborThunk,
		setLaborDatePickerVisible,
	} = useStoreActions((actions) => actions);

	const isFocused = useIsFocused();

	const getFilteredEmployees = (allEmployees, laborItems) => {
		const employedInLaborItems = new Set(
			laborItems.map((item) => item.EmployeeID)
		);
		return allEmployees.filter(
			(emp) => !employedInLaborItems.has(emp.EmployeeID)
		);
	};
	const filteredEmployees = getFilteredEmployees(employees, laborLineItems);

	return (
		<CustomModal
			isVisible={laborModalVisible}
			onClose={() => setLaborModalVisible(false)}
			title="Labor"
			style={{ width: width * 0.9, height: height * 0.9 }}
			width="50%"
			height="50%"
		>
			{laborDatePickerVisible && <LaborDatePicker />}

			<Row>
				<Col xs={12} md={2}>
					<Input
						placeholder="Type in Production Number"
						label="Production Number"
						value={selectedDispatch}
						editable={false}
					/>
				</Col>
				<Col xs={12} md={2}>
					<Input
						placeholder="Type in Operation Number"
						label="Operation Number"
						value={opNbr}
						editable={false}
					/>
				</Col>
				<Col xs={12} md={8}>
					<Input
						placeholder="Type in Description"
						label="Description"
						value={
							// laborLineItems.length > 0
							// 	? laborLineItems[0].Description
							// 	: input.Description.value
							currentDispatchObject.Description
						}
						editable={false}
						onChangeText={(text) => {
							setLaborInputAction({
								...input,
								Description: {
									value: text,
								},
							});
						}}
					/>
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col xs={12} md={2.5}>
					<Input
						placeholder="Type in Date"
						label="Date"
						value={moment(input.Date?.value).format("MM/DD/YYYY")}
						onClick={() => {
							setLaborDatePickerVisible(true);
						}}
						rightIcon={<Ionicons name="calendar" size={20} color="#00205b" />}
					/>
				</Col>
				<Col xs={12} md={2}>
					<Input
						value={input.Details[0].LaborType.value}
						placeholder="Type in Order Type"
						label="Order Type"
						onSelect={(selectedItem) => {
							setLaborInputAction({
								Details: [
									{
										...input.Details[0],
										LaborType: {
											value: selectedItem.label,
										},
										LaborCode: {
											value:
												selectedItem.label == "Direct" ? "DIRLAB" : "INDIRLAB",
										},
									},
								],
							});
						}}
						editable={true}
						selectedItem={(item) => item.label}
						buttonTextAfterSelection={(selectedItem) => {
							return selectedItem.label;
						}}
						rowTextForSelection={(item) => {
							return item.label;
						}}
						dropdownStyle={{
							height: 60 * 2,
						}}
						dropdown
						dropdownChoices={[
							{ value: "0010", label: "Direct" },
							{ value: "0020", label: "Indirect" },
						]}
						width={"100%"}
					/>
				</Col>

				<Col xs={12} md={3}>
					<Input
						value={input.Details[0].EmployeeID.value}
						placeholder="Type in Employee ID"
						label="Employee ID"
						onSelect={(selectedItem) => {
							setLaborInputAction({
								Details: [
									{
										...input.Details[0],
										EmployeeID: {
											value: selectedItem.EmployeeID,
										},
									},
								],
							});
						}}
						buttonTextAfterSelection={(selectedItem, index) => {
							return selectedItem.EmployeeID;
						}}
						rowTextForSelection={(item, index) => {
							return item.EmployeeID + " - " + item.LegalName;
						}}
						editable={true}
						selectedItem={(item) => item.EmployeeID + " - " + item.LegalName}
						dropdown
						// if the labor items length > 0 and an employee is already on the line item and the operation number matches the current operation, DONT INCLUDE THE EMPLOYEE

						// dropdownChoices={employees}
						dropdownChoices={filteredEmployees}
						width={"100%"}
						search={true}
						searchInputStyle={{
							width: "100%",
							alignSelf: "center",
							backgroundColor: "#F7F7F7",
							border: 0,

							boxShadow: "0px 0px 10px grey",
							borderColor: "#F7F7F7",
						}}
					/>
				</Col>
				<Col xs={12} md={1.5}>
					<Input
						placeholder="00:00"
						label="Start Time"
						value={input.Details[0].StartTime.value}
						editable={true}
						onChangeText={(text) => {
							// Remove non-numeric characters
							const numericText = text.replace(/[^0-9]/g, "");

							// Format the text
							let formattedText = "";
							if (numericText.length <= 2) {
								formattedText = numericText.padStart(2, "0");
							} else {
								formattedText =
									numericText.slice(-4, -2) + ":" + numericText.slice(-2);
							}
							setLaborInputAction({
								Details: [
									{
										...input.Details[0],
										StartTime: {
											// value: text,
											value: formattedText,
										},
									},
								],
							});
						}}
					/>
				</Col>

				<Col xs={12} md={1.5}>
					<Input
						placeholder="00:00"
						label="End Time"
						value={input.Details[0].EndTime.value}
						editable={true}
						onChangeText={(text) => {
							// Remove non-numeric characters
							const numericText = text.replace(/[^0-9]/g, "");

							// Format the text
							let formattedText = "";
							if (numericText.length <= 2) {
								formattedText = numericText.padStart(2, "0");
							} else {
								formattedText =
									numericText.slice(-4, -2) + ":" + numericText.slice(-2);
							}
							setLaborInputAction({
								Details: [
									{
										...input.Details[0],
										EndTime: {
											// value: text,
											value: formattedText,
										},
									},
								],
							});
						}}
						blurOnSubmit={true}
						// on Unfocus
						//onUnfocusEvent
						onBlur={() => {
							const difference = moment
								.utc(
									moment(input.Details[0].EndTime.value, "h:mm A").diff(
										moment(input.Details[0].StartTime.value, "h:mm A")
									)
								)
								.format("HH:mm");
							setLaborInputAction({
								Details: [
									{
										...input.Details[0],
										LaborTime: {
											value: difference,
										},
									},
								],
							});
						}}
					/>
				</Col>
				<Col xs={12} md={1.5}>
					{/* <Input
						placeholder="00:00"
						label="Labor Time"
						value={input.Details[0].LaborTime.value}
						editable={true}
						onChangeText={(text) => {
							setLaborInputAction({
								Details: [
									{
										...input.Details[0],
										LaborTime: {
											value: text,
										},
									},
								],
							});
						}}
					/> */}
					<Input
						placeholder="00:00"
						label="Labor Time"
						value={input.Details[0].LaborTime.value}
						editable={true}
						onChangeText={(text) => {
							// Remove non-numeric characters
							const numericText = text.replace(/[^0-9]/g, "");

							// Format the text
							let formattedText = "";
							if (numericText.length <= 2) {
								formattedText = numericText.padStart(2, "0");
							} else {
								formattedText =
									numericText.slice(-4, -2) + ":" + numericText.slice(-2);
							}

							// Update the state with the formatted text
							setLaborInputAction({
								Details: [
									{
										...input.Details[0],
										LaborTime: {
											value: formattedText,
										},
									},
								],
							});
						}}
					/>
				</Col>
			</Row>

			<Row>
				<Col>
					<Button
						fullWidth
						disabled={
							input.Details[0].EmployeeID.value == "" ||
							input.Details[0].LaborType.value == ""
						}
						onPress={async () => {
							if (input.Details[0].LaborTime.value == "") {
								const startTime = moment().format("h:mm A");
								const endTime = moment().add(1, "minutes").format("h:mm A");

								// calculate the difference between them in 00:00 format
								const difference = moment
									.utc(
										moment(endTime, "h:mm A").diff(moment(startTime, "h:mm A"))
									)
									.format("HH:mm");

								await setLaborInputAction({
									Details: [
										{
											...input.Details[0],
											StartTime: {
												value: startTime,
											},
											EndTime: {
												value: endTime,
											},
											LaborTime: {
												value: difference,
											},
										},
									],
								});
							}

							createLaborThunk();
						}}
					>
						{"Clock In"}
					</Button>
				</Col>
			</Row>
			<Divider />
			<Divider />
			<View
				style={{
					height: "30%",
				}}
			>
				<LaborList />
			</View>
			<Gap />
		</CustomModal>
	);
};

export default LaborModal;
