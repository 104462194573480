const state = {
  docs: {
    docUrl: '',
    excelUrl: '',
    webViewActive: false,
    webViewURL: '',
    fileList: [],
  },
};

export default state;
