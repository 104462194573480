import moment from "moment";
import List from "../../presentational/List";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Dimensions, TouchableOpacity, View } from "react-native";
import Text from "../../presentational/Text";

const { height } = Dimensions.get("window");

function ProductionOrderList() {
	const { workCenterDispatch, dispatchLoading, selectedDispatch } =
		useStoreState((state) => state);

	const { selectDispatchThunk, setCurrentDispatchObject } = useStoreActions(
		(actions) => actions
	);

	const Element = ({ data, index }) => (
		<TouchableOpacity
			onPress={async () => {
				setCurrentDispatchObject(workCenterDispatch[index]);
				selectDispatchThunk(data);
			}}
		>
			<View>
				<Text style={styles.dispatchElementText}>{data}</Text>
			</View>
		</TouchableOpacity>
	);

	return (
		<List
			doNotScroll={false}
			onPress={(data, index) => {
				selectDispatchThunk(data.props.data);
				setCurrentDispatchObject(workCenterDispatch[data.props.index]);
			}}
			selectItem={
				selectedDispatch == ""
					? -1
					: workCenterDispatch.findIndex(
							(item) => item.ProductionNbr === selectedDispatch
					  )
			}
			loading={dispatchLoading}
			tableHead={[
				"Production Number",
				"Description",
				"Inventory ID",
				"Inventory Description",
				"Status",
				"Qty to Produce",
				"Start Date",
				"End Date",
			]}
			tableRows={workCenterDispatch.map((item, index) => {
				return [
					<Element data={item.ProductionNbr} index={index} />,
					item.OrderDescription,
					item.InventoryID,
					item.Description,
					item.Status,
					parseInt(item.QtytoProduce),
					moment(item.StartDate).format("MM/DD/YYYY"),
					moment(item.EndDate).format("MM/DD/YYYY"),
				];
			})}
		/>
	);
}

export default ProductionOrderList;

const styles = {
	dispatchElementText: {
		fontSize: 25 - height / 150,
		textAlign: "center",
		alignSelf: "center",
		padding: 10,
		textDecorationLine: "underline",
		color: "blue",
	},
};
