import List from "../../presentational/List";
import { useStoreState } from "easy-peasy";

function StepsList() {
	const { selectedOperation } = useStoreState((state) => state);

	return (
		<List
			tableHead={["Description", "Line Order"]}
			tableRows={
				selectedOperation &&
				selectedOperation.Steps &&
				selectedOperation.Steps.length > 0
					? selectedOperation.Steps.map((item) => {
							return [item.Description.value, item.LineID.value];
					  })
					: []
			}
		/>
	);
}

export default StepsList;
