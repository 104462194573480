import React, { useEffect } from "react";
import CustomModal from "../../presentational/Modal";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Divider } from "react-native-elements";
import Col from "../../presentational/Col";
import Row from "../../presentational/Row";
import Input from "../../presentational/Input";
import Button from "../../presentational/Button";
import LaborList from "../List/LaborList";
import { useIsFocused } from "@react-navigation/native";
import { TouchableOpacity, View } from "react-native";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import Gap from "../../presentational/Gap";
import moment from "moment";
import { height, width } from "../../../utilities/general";
import List from "../../presentational/List";
import Text from "../../presentational/Text";

const MaterialListModal = () => {
	const { materialListModalVisible, selectedOperation } = useStoreState(
		(state) => state
	);

	const { setMaterialListModalVisibleAction } = useStoreActions(
		(actions) => actions
	);

	const Element2 = ({ data, color }) => (
		<View
			style={{
				flexDirection: "row",
				alignContent: "center",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Text
				style={{
					fontSize: 22 - height / 150,
					textAlign: "center",
					alignSelf: "center",
					padding: 10,
					color: color,
					fontWeight: "bold",
				}}
			>
				{data}
			</Text>
		</View>
	);
	return (
		<CustomModal
			isVisible={materialListModalVisible}
			onClose={() => setMaterialListModalVisibleAction(false)}
			title="Material"
			style={{ width: width * 0.9, height: height * 0.9 }}
			width="50%"
			height="50%"
		>
			<List
				onPress={() => {
					console.log();
				}}
				maxHeight={1.5}
				listHeight={1.5}
				tableHead={[
					"Inventory ID",
					"Description",
					"Qty Required",
					"Total Required",
					"UOM",
					"Qty Issued",
					"Qty Remaining",
					"Batch Size",
				]}
				tableRows={
					selectedOperation &&
					selectedOperation.Material &&
					selectedOperation.Material.length > 0
						? selectedOperation.Material.map((item) => {
								return [
									<Element2
										data={item.InventoryID.value}
										color={
											item.QtyRequired.value < item.QtyActual.value
												? "green"
												: "red"
										}
									/>,
									item.Description.value,
									item.QtyRequired.value,
									item.TotalRequired.value,
									item.UOM.value,
									item.QtyActual.value,
									item.QtyRemaining.value,
									item.BatchSize.value,
								];
						  })
						: []
				}
			/>
		</CustomModal>
	);
};

export default MaterialListModal;
