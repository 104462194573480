import List from "../../presentational/List";
import { useStoreState } from "easy-peasy";

function ToolsList() {
	const { selectedOperation } = useStoreState((state) => state);

	return (
		<List
			tableHead={["ToolID", "Description", "Qty Required", "Total Actual Uses"]}
			tableRows={
				selectedOperation &&
				selectedOperation.Tools &&
				selectedOperation.Tools.length > 0
					? selectedOperation.Tools.map((item) => {
							return [
								item.ToolID.value,
								item.Description.value,
								item.QtyRequired.value,
								item.TotalActualUses.value,
							];
					  })
					: []
			}
		/>
	);
}
export default ToolsList;
