import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";

import CustomModal from "../../presentational/Modal";
import Col from "../../presentational/Col";
import Input from "../../presentational/Input";
import Row from "../../presentational/Row";
import Button from "../../presentational/Button";

const MoveModal = () => {
	const {
		selectedDispatch,
		move: { moveModalVisible, input },
		currentDispatchObject,
		selectedOperation,
	} = useStoreState((state) => state);

	const {
		createMoveThunk,
		setMoveModalVisible,
		setMoveInputAction,
		selectOperation,
	} = useStoreActions((actions) => actions);

	useEffect(() => {
		setMoveInputAction({
			Description: {
				value: "",
			},
			ControlQty: {
				value: 0,
			},
			Details: [
				{
					...input.Details[0],
					Quantity: {
						value: 0,
					},
				},
			],

			TotalQty: {
				value: 0,
			},
		});
	}, [moveModalVisible]);

	return (
		<CustomModal
			onClose={() => {
				setMoveModalVisible(false);
			}}
			isVisible={moveModalVisible}
			title="Move"
			style={{ width: 800 }}
			width="50%"
			height="50%"
		>
			<Row>
				<Col xs={12} md={6}>
					<Input
						placeholder="Type in Production Number"
						label="Production Number"
						value={selectedDispatch}
						editable={false}
					/>
				</Col>

				<Col xs={12} md={6}>
					<Input
						placeholder="Type in Operation Number"
						label="Operation Number"
						value={
							selectedOperation &&
							selectedOperation.OperationNbr &&
							selectedOperation.OperationNbr.value
						}
						editable={false}
					/>
				</Col>
			</Row>
			<Row>
				<Col xs={12} md={3}>
					<Input
						placeholder="Type in Work Center"
						label="Work Center"
						value={currentDispatchObject && currentDispatchObject.WorkCenter}
						editable={false}
					/>
				</Col>

				<Col xs={12} md={3}>
					<Input
						placeholder="Move Qty"
						label="Move Qty"
						keyboardType="numeric"
						value={input.ControlQty.value}
						onChangeText={(ControlQty) => {
							// setMoveInputAction({
							// 	ControlQty: {
							// 		value: ControlQty,
							// 	},
							// 	Details: [
							// 		{
							// 			...input.Details[0],
							// 			Quantity: {
							// 				value: ControlQty,
							// 			},
							// 		},
							// 	],

							// 	TotalQty: {
							// 		value: ControlQty,
							// 	},
							// })
							if (/^\d+$/.test(ControlQty) || ControlQty === "") {
								setMoveInputAction({
									ControlQty: {
										value: ControlQty,
									},
									Details: [
										{
											...input.Details[0],
											Quantity: {
												value: ControlQty,
											},
										},
									],
									TotalQty: {
										value: ControlQty,
									},
								});
							}
						}}
					/>
				</Col>
				<Col xs={12} md={3}>
					<Input
						placeholder="Qty Complete"
						label="Qty Complete"
						value={
							selectedOperation &&
							selectedOperation.QtyComplete &&
							selectedOperation.QtyComplete.value == 0
								? "0"
								: selectedOperation &&
								  selectedOperation.QtyComplete &&
								  selectedOperation.QtyComplete.value
						}
						onChangeText={(ControlQty) =>
							setMoveInputAction({
								ControlQty: {
									value: ControlQty,
								},
								Details: [
									{
										...input.Details[0],
										Quantity: {
											value: ControlQty,
										},
									},
								],

								TotalQty: {
									value: ControlQty,
								},
							})
						}
						editable={false}
					/>
				</Col>
				<Col xs={12} md={3}>
					<Input
						placeholder="Qty Remaining"
						label="Qty Remaining"
						value={
							selectedOperation &&
							selectedOperation.QtyRemaining &&
							selectedOperation.QtyRemaining.value
						}
						editable={false}
						onChangeText={(ControlQty) =>
							setMoveInputAction({
								ControlQty: {
									value: ControlQty,
								},
								Details: [
									{
										...input.Details[0],
										Quantity: {
											value: ControlQty,
										},
									},
								],

								TotalQty: {
									value: ControlQty,
								},
							})
						}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Input
						placeholder="Type in Description"
						label="Description"
						value={input.Description.value}
						onChangeText={(description) =>
							setMoveInputAction({ Description: { value: description } })
						}
					/>
				</Col>
			</Row>
			<Col>
				<Button
					fullWidth
					onPress={async () => {
						await createMoveThunk();

						selectOperation(
							selectedOperation &&
								selectedOperation.OperationNbr &&
								selectedOperation.OperationNbr.value
						);
					}}
				>
					Create Move
				</Button>
			</Col>
		</CustomModal>
	);
};

export default MoveModal;
