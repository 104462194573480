import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { Dimensions, View, TouchableOpacity } from "react-native";
import SelectDropdown from "react-native-select-dropdown";
import DynamicText from "../../presentational/MyText";
import { AntDesign } from "@expo/vector-icons";

const { height } = Dimensions.get("window");

const WorkCenterCard = () => {
	const [val, setVal] = useState("");
	const { workCenters, desiredWorkCenter, workCenterString } = useStoreState(
		(state) => state
	);
	const {
		setDesiredWorkCenter,
		selectWorkCenterThunk,
		setWorkCenterString,
		getWorkCentersThunk,
		getWorkCentersThunkNoLoad,
	} = useStoreActions((actions) => actions);

	return (
		<>
			<View
				style={{
					...styles.cardView,
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<DynamicText text={"Work Center"} fontSize={12} />
				<SelectDropdown
					onFocus={() => {
					getWorkCentersThunkNoLoad();
					}}
					defaultButtonText={
						workCenterString !== "" ? workCenterString : "Select Work Center"
					}
					selectedItem={desiredWorkCenter}
					disableAutoScroll={true}
					value={desiredWorkCenter}
					searchInputStyle={{ width: 190 }}
					data={workCenters}
					dropdownStyle={{
						width: "25%",
						justifyContent: "space-evenly",
						height: "65%",
					}}
					onSelect={async (selectedItem, index) => {
						setWorkCenterString(
							selectedItem.WorkCenter + " - " + selectedItem.Description
						);
						setDesiredWorkCenter(selectedItem.WorkCenter);
						selectWorkCenterThunk(selectedItem.WorkCenter);
					}}
					buttonTextAfterSelection={(selectedItem, index) => {
						return selectedItem.WorkCenter + " - " + selectedItem.Description;
					}}
					rowTextForSelection={(item, index) => {
						return item.WorkCenter + " - " + item.Description;
					}}
					buttonTextStyle={{
						fontSize: 23 - height / 150,

						textAlign: "flex-start",
						width: "20%",
					}}
					buttonStyle={{
						width: "30%",
						marginLeft: "2%",
						borderRadius: 10,
					}}
					rowTextStyle={{
						fontSize: 20 - height / 150,
						textAlign: "flex-start",
						width: "100%",
						padding: 10,
					}}
				/>
				<TouchableOpacity>
					<AntDesign
						name="reload1"
						size={24}
						color="black"
						style={{
							marginLeft: 10,
						}}
						onPress={() => {
							selectWorkCenterThunk(desiredWorkCenter);
						}}
					/>
				</TouchableOpacity>
			</View>
		</>
	);
};

export default WorkCenterCard;

const styles = {
	cardView: {
		alignSelf: "center",
		alignContent: "flex-start",
		marginTop: "1%",
		width: "92%",
	},
};
