import React, { useEffect } from "react";
import { Dimensions, Platform, TouchableOpacity, View } from "react-native";
import WorkCenterCard from "../containers/Card/WorkCenterCard";
import DispatchCard from "../containers/Card/DispatchCard";
import OperationsCard from "../containers/Card/OperationsCard";
import MaterialCard from "../containers/Card/MaterialCard";
import Page from "../presentational/Page";
import MaterialButtonGroup from "../containers/ButtonGroup/MaterialButtonGroup";
import BottomButtonGroup from "../containers/ButtonGroup/BottomButtonGroup";
import TaskModal from "../containers/Modal/TaskModal";
import LaborModal from "../containers/Modal/LaborModal";
import MoveModal from "../containers/Modal/MoveModal";
import AreYouSureModal from "../containers/Modal/AreYouSureModal";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import LaborNotesModal from "../containers/Modal/LaborNotesModal";
import OperationListModal from "../containers/Modal/OperationListModal";
import MaterialListModal from "../containers/Modal/MaterialListModal";
import DispatchListModal from "../containers/Modal/DispatchListModal";
import { useIsFocused } from "@react-navigation/native";

const Col = ({ numRows, children }) => {
	return <View style={styles[`${numRows}col`]}>{children}</View>;
};

const TRow = ({ children }) => (
	<View style={Platform.OS === "web" ? styles.rowTWeb : styles.rowT}>
		{children}
	</View>
);

const MRow = ({ children }) => (
	<View style={Platform.OS === "web" ? styles.rowWeb : styles.row}>
		{children}
	</View>
);

const MRow1 = ({ children }) => (
	<View style={Platform.OS === "web" ? styles.rowWeb2 : styles.row2}>
		{children}
	</View>
);

const TopRow = ({ children }) => (
	<View style={Platform.OS === "web" ? styles.topRowWeb : styles.topRow}>
		{children}
	</View>
);

const Home = ({ navigation }) => {
	useEffect(() => {}, []);
	const {
		labor: { laborLineItems, selectedLaborIndex },
		userIsLoggedIn,
		acumaticaIsLoggedIn,
		user,
	} = useStoreState((state) => state);
	const isFocused = useIsFocused();

	const {
		processTimeThunk,
		setDispatchListModalVisibleAction,
		setOperationListModalVisibleAction,
		setMaterialListModalVisibleAction,
		getWorkCentersThunk,
	} = useStoreActions((actions) => actions);

	return (
		<Page
			style={{
				backgroundColor: "#D2D4D7",
				height: Dimensions.get("window").height,
			}}
		>
			<TaskModal />
			<LaborModal />
			<MoveModal />
			<LaborNotesModal />
			<OperationListModal />
			<MaterialListModal />
			<DispatchListModal />
			<AreYouSureModal
				onSubmit={() => {
					if (
						laborLineItems[selectedLaborIndex].StartTime == null &&
						laborLineItems[selectedLaborIndex].EndTime == null
					) {
						processTimeThunk({
							BatchNbr: {
								value: laborLineItems[selectedLaborIndex].BatchNbr,
							},
							Details: [
								{
									id: laborLineItems[selectedLaborIndex].AMTranNoteID,
									StartTime: {
										value:
											laborLineItems[selectedLaborIndex].newStartTime !==
											"Invalid date"
												? laborLineItems[selectedLaborIndex].newStartTime
												: "",
									},
									EndTime: {
										value:
											laborLineItems[selectedLaborIndex].newEndTime !==
											"Invalid date"
												? laborLineItems[selectedLaborIndex].newEndTime
												: "",
									},

									LaborTime: {
										value: laborLineItems[selectedLaborIndex].newLaborTime,
									},
								},
							],
						});
					} else if (laborLineItems[selectedLaborIndex].LaborTime == "00:01") {
						const startTime = moment(
							laborLineItems[selectedLaborIndex].StartTime
						).format("h:mm A");
						const endTime = moment().format("h:mm A");

						// calculate the difference between them in 00:00 format
						const difference = moment
							.utc(moment(endTime, "h:mm A").diff(moment(startTime, "h:mm A")))
							.format("HH:mm");
						// console.log({
						// 	BatchNbr: {
						// 		value: laborLineItems[selectedLaborIndex].BatchNbr,
						// 	},
						// 	Details: [
						// 		{
						// 			id: laborLineItems[selectedLaborIndex].AMTranNoteID,
						// 			StartTime: {
						// 				value: moment(
						// 					laborLineItems[selectedLaborIndex].StartTime
						// 				).format("h:mm A"),
						// 			},
						// 			EndTime: {
						// 				value: moment().format("h:mm A"),
						// 			},
						// 			LaborTime: {
						// 				value: moment
						// 					.utc(
						// 						moment(
						// 							laborLineItems[selectedLaborIndex].StartTime
						// 						).diff(moment().format("h:mm A"))
						// 					)
						// 					.format("HH:mm"),
						// 			},
						// 		},
						// 	],
						// });
						console.log({
							BatchNbr: {
								value: laborLineItems[selectedLaborIndex].BatchNbr,
							},
							Details: [
								{
									id: laborLineItems[selectedLaborIndex].AMTranNoteID,
									StartTime: {
										value: moment(
											laborLineItems[selectedLaborIndex].StartTime
										).format("h:mm A"),
									},
									EndTime: {
										value: moment().format("h:mm A"),
									},
									LaborTime: {
										value: difference,
									},
								},
							],
						});

						processTimeThunk({
							BatchNbr: {
								value: laborLineItems[selectedLaborIndex].BatchNbr,
							},
							Details: [
								{
									id: laborLineItems[selectedLaborIndex].AMTranNoteID,
									StartTime: {
										value: moment(
											laborLineItems[selectedLaborIndex].StartTime
										).format("h:mm A"),
									},
									EndTime: {
										value: moment().format("h:mm A"),
									},
									LaborTime: {
										value: difference,
									},
								},
							],
						});
					} else if (
						laborLineItems[selectedLaborIndex].newStartTime !==
							laborLineItems[selectedLaborIndex].StartTime &&
						laborLineItems[selectedLaborIndex].newEndTime !==
							laborLineItems[selectedLaborIndex].EndTime
					) {
						processTimeThunk({
							BatchNbr: {
								value: laborLineItems[selectedLaborIndex].BatchNbr,
							},
							Details: [
								{
									id: laborLineItems[selectedLaborIndex].AMTranNoteID,
									StartTime: {
										value: laborLineItems[selectedLaborIndex].newStartTime,
									},
									EndTime: {
										value: laborLineItems[selectedLaborIndex].newEndTime,
									},
									LaborTime: {
										value: moment
											.utc(
												moment(
													laborLineItems[selectedLaborIndex].newEndTime,
													"h:mm A"
												).diff(
													moment(
														laborLineItems[selectedLaborIndex].newStartTime,
														"h:mm A"
													)
												)
											)
											.format("HH:mm"),
									},
								},
							],
						});
					} else if (
						laborLineItems[selectedLaborIndex].newStartTime ==
							laborLineItems[selectedLaborIndex].StartTime &&
						laborLineItems[selectedLaborIndex].newEndTime ==
							laborLineItems[selectedLaborIndex].EndTime
					) {
						processTimeThunk({
							BatchNbr: {
								value: laborLineItems[selectedLaborIndex].BatchNbr,
							},
							Details: [
								{
									id: laborLineItems[selectedLaborIndex].AMTranNoteID,
									EndTime: {
										value: moment().format("h:mm A"),
									},
								},
							],
						});
					}
				}}
			/>

			<View style={styles.app}>
				<TRow>
					<Col numRows={1}>
						<WorkCenterCard />
					</Col>
				</TRow>

				<TopRow>
					<Col numRows={2}>
						<TouchableOpacity
							onPress={() => {
								setDispatchListModalVisibleAction(true);
							}}
						>
							<DispatchCard />
						</TouchableOpacity>
					</Col>
				</TopRow>

				<MRow>
					<Col numRows={3}>
						<TouchableOpacity
							onPress={() => {
								setOperationListModalVisibleAction(true);
							}}
						>
							<OperationsCard />
						</TouchableOpacity>
					</Col>
				</MRow>

				<MRow1>
					<Col numRows={3}>
						<View style={{ padding: "5px" }} />

						<MaterialButtonGroup />
						<TouchableOpacity
							onPress={() => {
								setMaterialListModalVisibleAction(true);
							}}
						>
							<MaterialCard />
						</TouchableOpacity>
					</Col>
				</MRow1>

				<BottomButtonGroup navigation={navigation} />
			</View>
		</Page>
	);
};

export default Home;

const styles = {
	app: {
		height: Dimensions.get("window").height,
		alignItems: "center",
	},
	row: {
		flexDirection: "row",
		height: Platform.OS === "web" ? "56%" : "26%",
		bottom: 0,
	},
	row2: {
		flexDirection: "row",
		height: Platform.OS === "web" ? "56%" : "25%",
		bottom: 0,
	},
	topRow: {
		flexDirection: "row",
		height: "25%",
		marginTop: "2.5%",
	},
	rowT: {
		flexDirection: "row",
		height: "10%",
	},
	"1col": {
		flexDirection: "row",
		borderColor: "#fff",
		width: "100%",
		alignContent: "center",
		justifyContent: "center",
		alignItems: "center",
	},
	"2col": {
		borderColor: "#fff",
		width: "100%",
	},
	"3col": {
		borderColor: "#fff",
		flex: 3,
		maxHeight: Platform.OS === "web" ? "99%" : "40%",
	},
	"4col": {
		flex: 4,
	},
	"5col": {
		flex: 4,
		marginBottom: 10,
	},
	rowWeb: {
		flexDirection: "row",
		width: "100%",
		height: Dimensions.get("window").height / 4,
	},
	rowWeb2: {
		flexDirection: "row",
		width: "100%",
		height: Platform.OS === "web" ? "30%" : "40%",
	},
	topRowWeb: {
		flexDirection: "row",
		width: "100%",
	},
	rowTWeb: {
		flexDirection: "row",
		width: "100%",
		top: 0,
	},
};
