import React from "react";
import { Dimensions, View } from "react-native";
import { useStoreActions, useStoreState } from "easy-peasy";
import MyButton from "../../presentational/MyButton";
import {
	FontAwesome5,
	SimpleLineIcons,
	MaterialIcons,
	MaterialCommunityIcons,
} from "@expo/vector-icons";
import moment from "moment";

const { height } = Dimensions.get("window");

export default function BottomButtonGroup({ navigation }) {
	const {
		selectedDispatch,
		opNbr,
		labor: { input },
	} = useStoreState((state) => state);
	const {
		setTaskModalVisible,
		setLaborModalVisible,
		setMoveModalVisible,
		getEmployeesThunk,
		getFileDetail,
		getLaborLineItemsThunk,
		logout,
		setLaborInputAction,
	} = useStoreActions((actions) => actions);

	const sharedButtonStyle = {
		width: 125,
		margin: 5,
		marginBottom: 15,
		alignContent: "center",
		alignItems: "center",
		height: 70,
		justifyContent: "center",
		alignSelf: "center",
		borderRadius: 10,
	};

	const textProps = {
		style: {
			fontSize: 23,
			padding: 5,
		},
	};

	return (
		<View style={styles.bottomButtonView}>
			<ButtonGroup
				icon={
					<MaterialCommunityIcons
						name="skip-forward-outline"
						size={35}
						color="black"
					/>
				}
				isSelected={!!opNbr?.length}
				title="Move"
				color={opNbr?.length ? "black" : "gray"}
				onPress={() => {
					setMoveModalVisible(true);
				}}
				sharedButtonStyle={sharedButtonStyle}
				textProps={textProps}
			/>

			<ButtonGroup
				icon={<FontAwesome5 name="business-time" size={24} color="black" />}
				isSelected={!!opNbr?.length}
				title="Labor"
				color={opNbr?.length ? "black" : "gray"}
				onPress={async () => {
					await setLaborInputAction({
						Date: { value: moment().format("MM/DD/YYYY") },
					});
					getLaborLineItemsThunk(new Date(input.Date.value));
					getEmployeesThunk();
					setLaborModalVisible(true);
				}}
				sharedButtonStyle={sharedButtonStyle}
				textProps={textProps}
			/>

			<ButtonGroup
				icon={<SimpleLineIcons name="docs" size={24} color="black" />}
				isSelected={selectedDispatch.length > 0}
				title="Docs"
				color={selectedDispatch.length > 0 ? "#1d242b" : "gray"}
				onPress={() => {
					getFileDetail();
					navigation.push("Docs");
				}}
				sharedButtonStyle={sharedButtonStyle}
				textProps={textProps}
			/>

			<ButtonGroup
				icon={<MaterialIcons name="add-task" size={24} color="black" />}
				isSelected={!!opNbr?.length}
				title="Task"
				color={opNbr?.length ? "black" : "gray"}
				onPress={() => {
					getEmployeesThunk();
					setTaskModalVisible(true);
				}}
				sharedButtonStyle={sharedButtonStyle}
				textProps={textProps}
			/>

			<ButtonGroup
				icon={<MaterialIcons name="logout" size={24} color="black" />}
				isSelected
				title="Logout"
				color="black"
				onPress={logout}
				sharedButtonStyle={sharedButtonStyle}
				textProps={textProps}
			/>
		</View>
	);
}

const ButtonGroup = ({
	icon,
	isSelected,
	title,
	color,
	onPress,
	sharedButtonStyle,
	textProps,
}) => (
	<View style={{ width: "20%", height: "10%" }}>
		<MyButton
			icon={icon}
			isSelected={isSelected}
			disabled={!isSelected}
			title={title}
			color={color}
			isNotSelectedStyle={{ ...sharedButtonStyle, opacity: 0.5 }}
			isSelectedStyle={sharedButtonStyle}
			textProps={textProps}
			onPress={onPress}
		/>
	</View>
);

const styles = {
	bottomButtonView: {
		width: "100%",
		flexDirection: "row",
		justifyContent: "space-between",
	},
};
