import { useStoreActions, useStoreState } from "easy-peasy";
import CustomModal from "../../presentational/Modal";
import Button from "../../presentational/Button";
import Text from "../../presentational/Text";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import Input from "../../presentational/Input";
import Gap from "../../presentational/Gap";

export default function LaborNotesModal({ confirmationPhrase, onSubmit }) {
	const {
		labor: { laborLineItems, selectedLaborIndex, laborNoteModalVisible },
		document,
		selectedDispatch,
		opNbr,
		selectedEmployee,
	} = useStoreState((state) => state);

	const {
		setLaborNoteModalVisibleAction,
		setNewLaborNoteModalInputAction,
		processTimeThunk,
	} = useStoreActions((actions) => actions);

	return (
		<CustomModal
			style={{ width: 500 }}
			width="50%"
			height="50%"
			title="Notes"
			onClose={() => setLaborNoteModalVisibleAction(false)}
			isVisible={laborNoteModalVisible}
		>
			<Col>
				<Input
					// label="Notes"
					multiline
					numberOfLines={4}
					value={
						laborLineItems.length > 0 &&
						laborLineItems[selectedLaborIndex].newNote !== null
							? laborLineItems[selectedLaborIndex].newNote
							: ""
					}
					onChangeText={(text) => {
						setNewLaborNoteModalInputAction({
							input: text,
							index: selectedLaborIndex,
						});
					}}
				/>
			</Col>
			<Gap />
			<Button
				fullWidth
				onPress={() =>
					processTimeThunk({
						BatchNbr: {
							value: laborLineItems[selectedLaborIndex].BatchNbr,
						},
						Details: [
							{
								id: laborLineItems[selectedLaborIndex].AMTranNoteID,
								note: {
									value: laborLineItems[selectedLaborIndex].newNote,
								},
								LaborType: {
									value: "Direct", //laborType,
								},
								ProductionNbr: {
									value: selectedDispatch,
								},
								// OperationNbr: {
								// 	value: opNbr,
								// },
								// EmployeeID: {
								// 	value: laborLineItems[selectedLaborIndex].EmployeeID,
								// },
								// Shift: {
								// 	value: "1",
								// },
								// INDocType: {
								// 	value: "Receipt",
								// },
								// OrderType: {
								// 	value: "RO",
								// },
								// QtyisScrap: {
								// 	value: false,
								// },
								// UOM: {
								// 	value: "EA",
								// },
							},
						],
					})
				}
			>
				Update
			</Button>
		</CustomModal>
	);
}
