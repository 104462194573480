import React from 'react';
import { View } from 'react-native';
import { useStoreActions, useStoreState } from 'easy-peasy';
import MyButton from '../../presentational/MyButton.js';

export default function MaterialButtonGroup() {
  const { tools, materialActive, toolsActive, stepsActive } = useStoreState(
    (state) => state
  );
  const {
    setMaterialActiveAction,
    setStepsActiveAction,
    setToolsActiveAction,
  } = useStoreActions((actions) => actions);

  return (
    <View style={styles.materialButtonListView}>
      <MyButton
        isSelected={materialActive}
        title="Material"
        color={materialActive ? 'black' : 'gray'}
        isNotSelectedStyle={styles.isNotSelectedStyle}
        isSelectedStyle={styles.isNotSelectedStyle}
        textProps={{
          style: styles.buttonTextProps,
        }}
        onPress={async () => {
          setMaterialActiveAction(true);
        }}
      />
      <MyButton
        isSelected={stepsActive}
        title="Steps"
        color={stepsActive ? 'black' : 'gray'}
        isNotSelectedStyle={styles.isNotSelectedStyle}
        isSelectedStyle={styles.isNotSelectedStyle}
        textProps={{
          style: styles.buttonTextProps,
        }}
        onPress={async () => {
          setStepsActiveAction(true);
        }}
      />
      <MyButton
        isSelected={toolsActive}
        title="Tools"
        disabled={tools && tools.length == 0}
        color={toolsActive ? 'black' : 'gray'}
        isNotSelectedStyle={styles.isNotSelectedStyle}
        isSelectedStyle={styles.isNotSelectedStyle}
        textProps={{
          style: styles.buttonTextProps,
        }}
        onPress={async () => {
          setToolsActiveAction(true);
        }}
      />
    </View>
  );
}

const styles = {
  materialButtonListView: {
    flexDirection: 'row',
    top: '5%',
  },
  isNotSelectedStyle: {
    alignSelf: 'center',
    borderRadius: 10,
    justifyContent: 'center',
    marginLeft: '5%',
    alignItems: 'center',
    color: 'gray',
  },
  buttonTextProps: {
    fontSize: 20,
    padding: 5,
  },
};
