import { thunk } from "easy-peasy";
import {
	CreateLaborAPICall,
	GetLaborLineItemsAPICall,
	ReleaseLaborAPICall,
} from "../../services";
import moment from "moment";

const thunks = {
	createLaborThunk: thunk(async (actions, payload, helpers) => {
		actions.setLoadingAction(true);

		try {
			if (helpers.getState().labor.laborLineItems.length == 0) {
				let i = helpers.getState().labor.input.Details[0];
				const difference = moment
					.utc(
						moment(i.EndTime.value, "h:mm A").diff(
							moment(i.StartTime.value, "h:mm A")
						)
					)
					.format("HH:mm");
				await CreateLaborAPICall({
					...helpers.getState().labor.input,
					Details: [
						{
							note: {
								value: i.note.value,
							},
							LaborType: {
								value: "Direct",
							},
							ProductionNbr: {
								value: helpers.getState().selectedDispatch,
							},
							OperationNbr: {
								value: helpers.getState().opNbr.trim(),
							},
							EmployeeID: {
								value: i.EmployeeID.value,
							},
							LaborTime: {
								value: i.StartTime.value == "" ? i.LaborTime.value : difference,
							},
							StartTime: {
								value: i.StartTime.value,
							},
							EndTime: {
								value: i.EndTime.value,
							},
							Shift: {
								value: "1",
							},
							INDocType: {
								value: "Receipt",
							},
							InventoryID: {
								value: helpers.getState().currentDispatchObject.InventoryID,
							},
							OrderType: {
								value: helpers.getState().currentDispatchObject.OrderType_2,
							},
							QtyisScrap: {
								value: false,
							},
							// UOM: {
							// 	value: "EACH",
							// },
						},
					],
				});
			} else {
				let i = helpers.getState().labor.input.Details[0];
				const difference = moment
					.utc(
						moment(i.EndTime.value, "h:mm A").diff(
							moment(i.StartTime.value, "h:mm A")
						)
					)
					.format("HH:mm");

				await CreateLaborAPICall({
					...helpers.getState().labor.input,
					BatchNbr: {
						value: helpers.getState().labor.laborLineItems[0].BatchNbr,
					},
					Details: [
						{
							note: {
								value: i.note.value,
							},
							LaborType: {
								value: "Direct",
							},
							ProductionNbr: {
								value: helpers.getState().selectedDispatch,
							},
							OperationNbr: {
								// value: helpers.getState().currentDispatchObject.OperationID,
								value: helpers.getState().opNbr.trim(),
							},
							EmployeeID: {
								value: i.EmployeeID.value,
							},
							LaborTime: {
								value: i.StartTime.value == "" ? i.LaborTime.value : difference,
							},
							StartTime: {
								value: i.StartTime.value,
							},
							EndTime: {
								value: i.EndTime.value,
							},
							Shift: {
								value: "1",
							},
							INDocType: {
								value: "Receipt",
							},
							InventoryID: {
								value: helpers.getState().currentDispatchObject.InventoryID,
							},
							OrderType: {
								value: helpers.getState().currentDispatchObject.OrderType_2,
							},
							QtyisScrap: {
								value: false,
							},
							// UOM: {
							// 	value: "EACH",
							// },
						},
					],
				});
			}
			actions.getLaborLineItemsThunk();

			actions.setLaborInputAction({
				...helpers.getState().labor.input,
				Details: [
					{
						note: {
							value: "",
						},
						LaborType: {
							value: "Direct",
						},
						ProductionNbr: {
							value: helpers.getState().selectedDispatch,
						},
						OperationNbr: {
							// value: helpers.getState().currentDispatchObject.OperationID,
							// value: helpers.getState().opNbr,
							// trim the opNbr to remove spaces
							value: helpers.getState().opNbr.trim(),
						},
						EmployeeID: {
							value: "",
						},
						LaborTime: {
							value: "",
						},
						StartTime: {
							value: "",
						},
						EndTime: {
							value: "",
						},
						Shift: {
							value: "1",
						},
						INDocType: {
							value: "Receipt",
						},
						InventoryID: {
							value: helpers.getState().currentDispatchObject.InventoryID,
						},
						OrderType: {
							value: "WO",
						},
						QtyisScrap: {
							value: false,
						},
						// UOM: {
						// 	value: "EACH",
						// },
					},
				],
			});

			actions.setLaborModalVisible(false);
			actions.setAlertThunk({
				type: "success",
				message: "Labor created successfully.",
			});
		} catch (error) {
			alert("Labor was not created. Please try again.");
			console.log(error);
			actions.setAlertThunk({
				type: "error",
				message: "Labor was not created. Please try again.",
			});
		}
		actions.setLoadingAction(false);
	}),

	getLaborLineItemsThunk: thunk(async (actions, payload, helpers) => {
		actions.setLoadingAction(true);
		try {
			const response = await GetLaborLineItemsAPICall(
				helpers.getState().selectedDispatch,
				// helpers.getState().currentDispatchObject.OperationID
				helpers.getState().opNbr.trim(),
				payload ?? new Date()
			);
			console.log(response);

			// let r = response.data.data.map((item) => ({
			// 	LaborType: {
			// 		value: item.LaborType,
			// 	},
			// 	EmployeeID: {
			// 		value: item.EmployeeID,
			// 	},
			// 	OperationNbr: {
			// 		value: item.OperationID,
			// 	},
			// 	LaborTime: {
			// 		value: item.LaborTime,
			// 	},
			// }));

			actions.setLaborLineItemsAction(response.data.data);

			// actions.setLaborLineItemsAction(response.data.data);
		} catch (error) {
			actions.setAlertThunk({
				type: "error",
				message: "Unable to get labor line items. Please try again.",
			});
		}
		actions.setLoadingAction(false);
	}),

	processTimeThunk: thunk(async (actions, payload, helpers) => {
		actions.setLoadingAction(true);
		try {
			await CreateLaborAPICall(payload);
			actions.getLaborLineItemsThunk();
			actions.setAlertThunk({
				type: "success",
				message: "Time updated successfully.",
			});
		} catch (error) {
			alert("Labor was not processed. Please try again.");
			actions.setAlertThunk({
				type: "error",
				message: "Labor was not processed. Please try again.",
			});
		}
		actions.setLoadingAction(false);
	}),

	releaseLaborThunk: thunk(async (actions, guid, helpers) => {
		actions.setLoadingAction(true);
		try {
			await ReleaseLaborAPICall(guid);
			actions.setAlertThunk({
				type: "success",
				message: "Labor released successfully.",
			});
		} catch (error) {
			alert("Labor was not released. Please try again.");
			console.log(error);
			actions.setAlertThunk({
				type: "error",
				message: "Labor was not released. Please try again.",
			});
		}
		actions.setLoadingAction(false);
	}),

	validateCreateLaborThunk: thunk(async (actions, payload, helpers) => {
		const response = await GetLaborLineItemsAPICall(
			helpers.getState().selectedDispatch,
			helpers.getState().currentDispatchObject.OperationID
		);
		if (response.data.length > 0) {
			return true;
		} else {
			return false;
		}
	}),
	// if (helpers.getState().labor.laborLineItems.length == 0) {
	// We are going to make a get labor lines call here
	// If the labor line items are empty, we can create a new labor line item
	// If the labor line items are not empty, we need to check if the employee is already in the labor line items
	// If the employee is already in the labor line items, we need to add the labor time to the existing labor line item
};
export default thunks;
