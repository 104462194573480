import { useStoreState, useStoreActions } from 'easy-peasy';
import React, { useEffect } from 'react';
import {
  Text,
  View,
  ActivityIndicator,
  Platform,
  TouchableOpacity,
  ScrollView,
  Dimensions,
} from 'react-native';
import { Cell, Row, Table, TableWrapper } from 'react-native-table-component';

const { height } = Dimensions.get('window');

const Docs = ({ navigation }) => {
  const {
    user,
    modalLoading,
    docs: { fileList, docUrl, excelUrl, webViewActive, webViewURL },
  } = useStoreState((state) => state);

  const { getFileDetail, selectFile } = useStoreActions((actions) => actions);

  const [tableData, setTableData] = React.useState([]);

  useEffect(() => {
    if (fileList == []) {
      getFileDetail();
    } else {
      setTableData(
        fileList.map((item) => {
          return [item.filename];
        })
      );
    }
    if (webViewActive) {
      if (Platform.OS !== 'web') {
        navigation.push('Viewer');
      }
    }
  }, [webViewActive, webViewURL, fileList, excelUrl, docUrl]);

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const _handlePressButtonAsync = async (item) => {
    const payload = {
      item: item,
      id: item.id,
      token: user && user.token,
    };
    await selectFile(payload);
  };

  const element = (item, index) => (
    <TouchableOpacity onPress={() => _handlePressButtonAsync(fileList[index])}>
      <Text
        style={{
          fontSize: 20,
          textAlign: 'center',
          alignSelf: 'center',
          padding: 10,
          textDecorationLine: 'underline',
          color: 'blue',
        }}
      >
        {item.includes('.xlsx') ? (
          <a
            href={URL.createObjectURL(
              b64toBlob(fileList[index].fileDetails, 'application/octet-stream')
            )}
            download={
              URL.createObjectURL(
                b64toBlob(
                  fileList[index].fileDetails,
                  'application/octet-stream'
                )
              ) + '.xlsx'
            }
            onclick="window.open(this.href); return false;"
            onKeyPress="window.open(this.href); return false;"
          >
            {item.split('\\').pop()}
          </a>
        ) : item.includes('.docx') ? (
          <a
            href={URL.createObjectURL(
              b64toBlob(fileList[index].fileDetails, 'application/octet-stream')
            )}
            download={
              URL.createObjectURL(
                b64toBlob(
                  fileList[index].fileDetails,
                  'application/octet-stream'
                )
              ) + '.docx'
            }
            onclick="window.open(this.href); return false;"
            onKeyPress="window.open(this.href); return false;"
          >
            {item.split('\\').pop()}
          </a>
        ) : (
          item.split('\\').pop()
        )}
      </Text>
    </TouchableOpacity>
  );

  return (
    <View
      style={{
        alignSelf: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: '#D2D4D7',

        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {modalLoading ? (
        <ActivityIndicator
          size="large"
          style={{
            alignSelf: 'center',
            width: '100%',
            height: '100%',
          }}
        />
      ) : (
        <>
          <>
            <View
              style={{
                width: '92%',
                height: '90%',
                marginBottom: '5%',

                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  fontSize: 25,
                  margin: 10,
                  marginRight: 'auto',
                  // color: 'white',
                }}
              >
                Files
              </Text>
              <ScrollView
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'white',
                  alignSelf: 'center',
                }}
              >
                <Table
                  borderStyle={{
                    borderWidth: 1,
                    borderColor: '#C1C0B9',
                  }}
                >
                  <Row
                    data={['File Name']}
                    widthArr={['100%']}
                    style={{
                      height: 40,
                      flexDirection: 'row',
                      alignSelf: 'flex-start',
                      alignItems: 'center',
                      marginRight: 'auto',
                      width: '100%',
                      backgroundColor: '#F5F7F8',
                    }}
                    textStyle={{
                      fontSize: 20 - height / 150,

                      textAlign: 'center',
                      padding: 15,
                    }}
                  />
                  {tableData && tableData.length > 0 ? (
                    tableData.map((rowData, index) => (
                      <TableWrapper
                        key={index}
                        style={{
                          flexDirection: 'row',
                          height: 40,
                          backgroundColor: '#E7E6E1',
                        }}
                      >
                        {rowData.map((cellData, cellIndex) => (
                          <Cell
                            key={cellIndex}
                            data={
                              cellIndex === 0
                                ? element(cellData, index)
                                : cellData.split('\\').pop()
                            }
                            style={{
                              width: ['100%'][cellIndex],
                              backgroundColor:
                                index % 2 === 0 ? '#E8F0FE' : '#FBFCFF',

                              height: 40,
                            }}
                            textStyle={{
                              fontSize: 18,
                              textAlign: 'center',
                              alignSelf: 'center',
                              padding: 10,
                            }}
                          />
                        ))}
                      </TableWrapper>
                    ))
                  ) : (
                    <Text
                      style={{
                        fontSize: 20,
                        textAlign: 'center',
                        alignSelf: 'center',
                        padding: 10,
                      }}
                    >
                      No Files Found
                    </Text>
                  )}
                </Table>
              </ScrollView>
            </View>
          </>
        </>
      )}
    </View>
  );
};

export default Docs;
