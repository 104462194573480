import { thunk } from 'easy-peasy';
import { Platform } from 'react-native';
import { GetFileDetailAPICall, SelectFileAPICall } from '../../services';

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const thunks = {
  getFileDetail: thunk(async (actions, payload, helpers) => {
    // actions.setLoadingAction(true);
    actions.setModalLoading(true);

    try {
      const response = await GetFileDetailAPICall(
        helpers.getState().selectedDispatch
      );
      console.log(response)

      await actions.setFileList(response.data.data);
      actions.setModalLoading(false);
    } catch (error) {
      //handleError(error);
    }
    // actions.setLoadingAction(false);
    actions.setModalLoading(false);
  }),
  selectFile: thunk(async (actions, payload, helpers) => {
    const response = await SelectFileAPICall(payload.id);
    if (response.status === 200) {
      if (response.data.data !== '') {
        if (payload.item.filename && payload.item.filename.includes('.pdf')) {
          let blob = b64toBlob(response.data.data, 'application/pdf');
          if (Platform.OS === 'web') {
            let url = URL.createObjectURL(blob);
            window.open(url, '_blank');
          } else {
            // let url = URL.createObjectURL(blob);
            let base64Prefix = 'data:application/pdf;base64,';
            actions.setWebViewURL(base64Prefix + response.data.data);
            //  actions.setWebViewURL(url)
            actions.setWebViewActive(true);
          }
        } else if (
          payload.item.filename &&
          payload.item.filename.includes('.PDF')
        ) {
          let blob = b64toBlob(response.data.data, 'application/pdf');
          if (Platform.OS === 'web') {
            let url = URL.createObjectURL(blob);
            window.open(url, '_blank');
          } else {
            // let url = URL.createObjectURL(blob);
            let base64Prefix = 'data:application/pdf;base64,';
            actions.setWebViewURL(base64Prefix + response.data.data);
            //  actions.setWebViewURL(url)
            actions.setWebViewActive(true);
          }
          // let url = URL.createObjectURL(blob);
          // window.open(url, '_blank');
        } else if (
          payload.item.filename &&
          payload.item.filename.includes('.jpg')
        ) {
          const blob = b64toBlob(response.data.data, 'image/jpeg');
          if (Platform.OS === 'web') {
            let url = URL.createObjectURL(blob);
            window.open(url, '_blank');
          } else {
            // let url = URL.createObjectURL(blob);
            let base64Prefix = 'data:image/jpeg;base64,';
            actions.setWebViewURL(base64Prefix + response.data.data);
            //  actions.setWebViewURL(url)
            actions.setWebViewActive(true);
          }
          // const url = URL.createObjectURL(blob);
          // window.open(url, '_blank');
        } else if (
          payload.item.filename &&
          payload.item.filename.includes('.png')
        ) {
          const blob = b64toBlob(response.data.data, 'image/png');
          if (Platform.OS === 'web') {
            let url = URL.createObjectURL(blob);
            window.open(url, '_blank');
          } else {
            // let url = URL.createObjectURL(blob);
            let base64Prefix = 'data:image/jpeg;base64,';
            actions.setWebViewURL(base64Prefix + response.data.data);
            //  actions.setWebViewURL(url)
            actions.setWebViewActive(true);
          }
          // const url = URL.createObjectURL(blob);
          // window.open(url, '_blank');
        } else if (
          payload.item.filename &&
          payload.item.filename.includes('.xlsx')
        ) {
          const blob = b64toBlob(
            response.data.data,
            'application/octet-stream'
          );
          const url = URL.createObjectURL(blob);

          actions.setExcelUrl(url);
        } else if (
          payload.item.filename &&
          payload.item.filename.includes('.docx')
        ) {
          const blob = b64toBlob(
            response.data.data,
            'application/octet-stream'
          );
          const url = URL.createObjectURL(blob);

          await actions.setDocUrl(url);
        }
      }
    }
  }),
};
export default thunks;
