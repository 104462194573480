import { thunk } from "easy-peasy";
import {
	CreateMoveAPICall,
	SelectDispatchAPICall,
	SelectWorkCenterAPICall,
} from "../../services";

const thunks = {
	createMoveThunk: thunk(async (actions, payload, helpers) => {
		actions.setLoadingAction(true);
		try {
			await CreateMoveAPICall({
				...helpers.getState().move.input,
				Details: [
					{
						...helpers.getState().move.input.Details[0],
						ProductionNbr: {
							value: helpers.getState().selectedDispatch,
						},
						InventoryID: {
							value: helpers.getState().currentDispatchObject.InventoryID,
						},
						OperationNbr: {
							value: helpers.getState().selectedOperation.OperationNbr.value,
						},
					},
				],
			});

			let wc = await SelectWorkCenterAPICall(
				helpers.getState().desiredWorkCenter
			);
			await actions.setSelectedWorkCenter(wc.data.data);

			let dp = await SelectDispatchAPICall(helpers.getState().selectedDispatch);
			await actions.setOperations(dp.data.data[0].Operations);

			actions.setMoveModalVisible(false);
			actions.setAlertThunk({
				type: "success",
				message: "Move Created Successfully!",
			});
		} catch (error) {
			console.log(error);
			alert(error.response.data.data);
			actions.setAlertThunk({
				type: "error",
				message: error.response.data.data,
			});
		}
		actions.setLoadingAction(false);
	}),
};
export default thunks;
